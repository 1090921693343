.main-referral-block-automation-popup {
  width: 300px;
  height: auto;
  min-height: 300px;
  background-color: white;
  position: fixed;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  border: 4px solid lightblue;
  text-align: center;
  padding: 10px;
  z-index: 10000;
}

.main-referral-global-pause-play {
  outline: none;
  border: none;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: orangered;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.automation-popup-stop {
  position: absolute;
  right: 5px;
  top: 5px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: orange;
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.overlay_automation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
}

.automation-popup-edit-email {
  padding: 20px;
  margin: 20px;
}

.automation-popup-edit-email p {
  color: orangered;
  font-weight: 600;
}

.automation-popup-edit-email-text2 {
  color: #007bff !important;
  font-weight: bold;
  font-size: 14px;
}

.automation-popup-edit-email span {
  border-radius: 100%;
  background-color: orangered;
  color: white;
  padding: 5px 8px;
  font-size: 12px;
  margin-left: 5px;
  /* font-size: 15px; */
}

.automation-popup-edit-email button {
  outline: none;
  padding: 10px 15px;
  border-radius: 10px;
  color: white;
  border: none;
}

.automation-popup-edit-play {
  background-color: green;
}

.automation-popup-edit-pause {
  background-color: orangered;
}

.automation_start_modal {
  padding: 20px;
  font-weight: 400;
  font-size: 18px;
}

.automation_start_modal_button {
  margin: 20px;
}

.automation_start_modal_button button {
  background-color: darkcyan;
  color: white;
  border-radius: 10px;
  outline: none;
  padding: 10px 15px;
  border: none;
}

.form_main_automation {
  display: inline-block;
  width: auto;
  margin: 10px 10px;
  border: 2px solid gray;
  outline: none;
  color: black;
  font-weight: normal;
}

.form_main_automation_count {
  width: 100px;
}

.automation-popup-main-text {
  font-size: 20px;
  font-weight: bold;
  color: #007bff;
}

.main-referral-block-automation-popup img {
  width: 100px;
  margin-top: 10px;
  position: relative;
}

.automation-popup-step-text {
  font-size: 16px;
  margin-top: 30px;
  color: darkcyan;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-content: center;
}

.automation-popup-step-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.automation-popup-step-tick {
  width: 20px !important;
  height: 20px;
  margin-top: 0px !important;
}

.automation_start_modal_additional_settings {
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
  margin: 20px;
  padding: 20px;
  margin-bottom: 40px;
}

.automation_start_modal_additional_settings p {
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
}
