.goal-container-main {
  width: 100%;
  height: 100%;
  /* background-color: #f0eefa;
  background-color: #fbfbff; */
  /* background-color: #f9f9fe; */
  background-color: #fafafe;
  /* padding-top: 100px; */
  /* position: absolute; Position relative to the parent */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  /* background-color: lightgray; Optional styling */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.goal-container {
  /* max-width: 600px; */
  margin: 0px auto;
  text-align: center;
  width: 60%;
  height: auto;
  /* overflow: hidden; */
  padding: "20px";
  border-radius: 20px;
}

.goal-heading {
  font-size: 3rem;
  margin-bottom: 100px;
  margin-top: 0px;
  color: black;
  transition: all 1s ease;
  position: relative;
  display: inline-block;
}

.goal-heading::after {
  content: "";
  position: absolute;
  left: 25%; /* Center the line under the text (25% left + 50% width) */
  bottom: -5px; /* Adjust this value for spacing between text and line */
  width: 50%; /* Line width is half of the text width */
  height: 2px; /* Thickness of the line */
  background-color: #968bdc; /* Color of the line */
}

.mainformuploadresume label {
  color: gray;
  font-weight: 500;
}

.goal-description button {
  outline: none;
  border: none;
  padding: 5px 15px;
  color: slateblue;
  background-color: #f0eefa;
  border: 2px solid slateblue;
  cursor: pointer;
  font-size: 20px;
  border-radius: 10px;
}

.goal-description {
  margin: 0px;
  max-width: 100%;
  width: inherit;
  font-weight: unset;
  line-height: 40px;
  font-size: 25px;
  color: rgba(25, 25, 25, 0.7);
  text-align: left;
  margin-bottom: 20px;
  display: flex;
}

.goal-description > div:first-child {
  white-space: nowrap;
  display: inline-block;
}

.dash-input {
  display: inline-block;
  width: 100%;
  border-bottom: 2px solid #c3bdeb;
  margin: 0 20px;
  transition: background-color 1e8s, box-shadow 0.1s ease-out;
}

.dash-input.dash-input-error {
  border-bottom: 2px solid red;
}

.dash-input .form-control {
  border: none;
  border-radius: 0;
  box-shadow: none;
  text-align: left;
  display: inline;
  font-size: 1rem;
  padding: 2px;
  background: transparent;
}

.dash-input .form-control:focus {
  outline: none;
  box-shadow: none;
}

.goal-back-button {
  position: absolute;
  right: 10px;
  top: 10px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  z-index: 1000;
}
