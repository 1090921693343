.main-referral-prompt-examples {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* margin-top: 100px; */
  }
  
  .fadeDiv {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .showr {
    text-align: center;
    font-weight: 500;
    color: green;
    font-size: 18px;
    margin-top: 10px;
  }
  
  .showr .error {
    color: orangered;
  }
  
  .profile-dropdown {
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer !important;
  }
  
  .profile-dropdown #dropdown-basic {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    background: #f0eefa;
    box-shadow: none;
    border: none;
  }
  
  .profile-dropdown .dropdown-toggle::after {
    font-size: 20px;
    color: slateblue;
    box-shadow: none;
  }
  
  .profile-dropdown .dropdown-item:hover {
    background-color: #f0eefa;
    box-shadow: none;
    border: none;
  }
  
  .profile-dropdown .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    float: right !important;
    box-shadow: none;
  }
  
  .navbarImg {
    width: 60px;
  }
  
  .main-referral-companies-container {
    width: 80%;
    padding-top: 100px;
  }
  
  .main-referral-prompt-examples-top-text {
    font-size: 14px;
    text-align: center;
    color: gray;
    width: 100%;
  }
  
  .main-referral-create-goal-container {
    display: flex;
    align-items: top;
    height: 100px;
  }
  
  .main-referral-create-goal-button-div {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: top;
  }
  
  .main-referral-create-goal {
    outline: none;
    border: none;
    padding: 5px 15px;
    color: slateblue;
    background-color: #f0eefa;
    border: 2px solid slateblue;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .main-referral-create-goal-dropdown p {
    color: grey;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    margin-left: 15px;
  }
  
  .main-referral-search-input-group {
    background-color: white;
    border: 2px solid gray;
    padding: 2px 2px !important;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 90%;
    margin-top: 5%;
    margin-left: 5%;
  }
  
  .main-referral-search-input-group textarea {
    border: none;
    outline: none;
    background-color: white;
    border-radius: 15px !important;
  }
  
  .main-referral-search-input-group textarea:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  .main-referral-search-input-group.focused {
    border-color: slateblue;
  }
  
  .main-referral-search-input-group.focused
    .main-referral-search-input-group-submit {
    background-color: #a59ce1;
  }
  
  .main-referral-search-toptext {
    font-size: 25px;
    font-weight: 500;
    color: slateblue;
    text-align: center;
  }
  
  .main-referral-search-input-group-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: gray;
    margin: auto;
    cursor: pointer;
  }
  
  .main-referral-search-input-group-submit i {
    font-size: 18px;
    cursor: pointer;
    color: white;
    transform: scaleX(0.8);
  }
  
  .main-referral-prompt-examples-innerdiv {
    color: black;
    background-color: #f0eefa;
    font-weight: 300;
    font-size: 14px;
    padding: 25px;
    text-align: center;
    width: 200px;
    border-radius: 10px;
    height: 100%;
  }
  
  .main-referral-prompt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .main-referral-prompt-examples-innerdiv:hover {
    cursor: pointer;
    font-weight: 400;
    border: 1px solid slateblue;
    box-shadow: 1px 1px 1.5px 1.5px #efefef, 1px 1px 1.5px 1.5px #efefef;
  }
  
  .main-referral-loading-companies {
    background-color: white;
    padding: 50px;
    font-weight: 500;
    width: 50%;
    background: "white";
    border: 1px solid lightblue;
    border-radius: 10px;
  }
  
  .main-referral-loading-companies-img {
    width: 250px;
    height: 250px;
  }
  
  .dynamic-text {
    font-size: "16px";
    text-align: "center";
    color: slateblue;
  }
  
  .resetResume {
    border: none;
    outline: none;
    background-color: orange;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
    word-wrap: normal;
  }
  
  /* Skeleton Loader Styles */
  .skeleton-loader {
    background-color: #e0e0e0;
    border-radius: 4px;
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s ease infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
  .skeleton-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fafafe;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow: hidden;
    z-index: 1000; /* Ensures overlay above all content */
  }
  
  /* Adjusting skeleton items for a responsive layout */
  .skeleton-header {
    width: 100%;
    max-width: 200px;
    height: 20px;
    margin-bottom: 16px;
  }
  
  .skeleton-search-bar {
    width: 90%;
    max-width: 600px;
    height: 40px;
    border-radius: 20px;
    margin-top: 20px;
  }
  
  .skeleton-text {
    width: 80%;
    max-width: 500px;
    height: 20px;
    margin-bottom: 8px;
  }
  
  .skeleton-prompt-card {
    width: 100%;
    max-width: 250px;
    height: 100px;
    border-radius: 8px;
    margin: 8px;
  }
  
  .uploadbox {
    display: block;
    width: 100%;
    margin: 20px 0 40px 0px;
  }
  
  .uploadbox p {
    font-weight: bold;
  }
  
  .resume-view {
    display: flex;
    flex-direction: row;
    background-color: aliceblue;
    border-radius: 10px;
  }
  
  .resume-view-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .resume-load {
    /*center content*/
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .resume-load .loading {
    position: relative;
    background-color: lightgray;
    min-width: 10px;
    min-height: 10px;
    height: 10px;
    border-radius: 1em;
  }
  
  .resume-view-label {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  
  .resume-view-img .stripeimgsecure {
    margin: 0px;
  }
  
  .resume-view-cancel {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    padding: 5px 15px;
    color: slateblue;
    background-color: #f0eefa;
    border: 2px solid slateblue;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .resume-view-img img {
    width: 50px;
  }
  