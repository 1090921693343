.main-referral-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px; 
  margin-top: 100px;
}


.main-referral-container {
    /* background-color: #e5f3ff; */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 1.5px 1.5px #efefef;
  margin: 10px;
  width: calc(33.33% - 20px); 
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  }
  
  .main-referral-container_new_format {
    margin: 10px;
    padding: 5px;
  }
  
  .main-referral-container_new_format {
    padding: 10px;
    border: 2px solid #ddd;
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease;
  }
  
  .main-referral-select-all-main-div {
    display: flex;
    margin-top: 50px;
  }
  
  .main-referral-select-all-second-div {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .main-referral-select-all {
    background-color: #fafafe;
    margin-left: 10px;
    border: 2px solid slateblue;
    color: slateblue;
    outline: none;
    padding: 8px 15px;
    white-space: nowrap;
    border-radius: 5px;
  }
  
  .main-referral-select-all-start-automation {
    background: #786ad2;
    color: white;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    margin-right: 10px;
  }
  
  .main-referral-select-all-start-automation:hover {
    background-color: slateblue;
  }
  
  .main-referral-container_new_format.selected {
    border-color: #d2cdf0;
    background-color: #f0eefa;
  }
  
  .selected .main-referral-top {
    background-color: white !important;
  }
  
  .main-referral-container_new_format:hover {
    border-color: #a59ce1;
  }
  
  .custom-checkbox input {
    transform: scale(1.5);
    cursor: pointer;
  }
  
  .custom-checkbox {
    position: absolute;
    top: 15px;
    left: 35px;
  }
  
  .main-referral-modal-loading {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 10px;
    color: #007bff;
    font-weight: 400;
  }
  
  .main-referral-modal-loading-email {
    font-size: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 10px;
    color: #007bff;
    font-weight: 400;
  }
  
  .main-referral-modal-feedback-open-button {
    background-color: tomato;
    width: 100px;
    outline: none;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    cursor: pointer;
    padding: 5px;
    position: absolute;
    right: 40px;
    top: 5px;
    z-index: 10000;
  }
  
  .main-referral-modal-loading-email-body {
    font-size: 15px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: blue;
    font-weight: 400;
    margin-top: 20px;
  }
  
  .main-referral-container-modal {
    margin: 0px;
  }
  
  .main-referral-container-peoplelist {
    padding: 0px;
    margin: 0px;
    margin-top: 20px;
  }
  
  .main-referral-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 5px;
    background-color: #f0eefa;
  }
  
  .main-referral-container-peoplelist .main-referral-top {
    background-color: ghostwhite;
  }
  
  .main-referral-top-first {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .main-referral-tags {
    display: inline-block;
  }
  
  .main-referral-tag-content {
    display: inline-block;
    font-size: 14px;
    background-color: aliceblue;
    margin: 10px 5px;
    padding: 2px 5px;
    border-radius: 5px;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .main-referral-tag-content-main {
    color: cornflowerblue;
    font-weight: bold;
  }
  
  .main-referral-tag-content-button {
    background: lavender;
    cursor: pointer;
  }
  
  .main-referral-company-name {
    font-size: 16px;
    color: black;
    margin-left: 10px;
    /* font-weight: 200; */
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    display: block;
    max-width: 100%;
  }
  
  .fa-linkedin {
    color: #0b65c2;
    font-size: 20px;
  }
  
  .main-referral-company-domain {
    font-size: 12px;
    color: darkslategrey;
    margin-left: 10px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    display: block;
    max-width: 100%;
  }
  
  .main-referral-company-name i {
    font-size: 25px;
    margin-left: 10px;
    cursor: pointer;
    color: #0b65c2;
    font-size: 20px;
    
  }
  
  .main-referral-top-second {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    width: 100%;
    /* background-color: blue; */
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    display: block;
    max-width: 100%;
  }
  
  .main-referral-top-third {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
  }
  
  .main-referral-top-third button {
    white-space: nowrap;
    outline: none;
    border: none;
    padding: 5px 15px;
    color: slateblue;
    background-color: #f0eefa;
    border: 2px solid slateblue;
    cursor: pointer;
    font-size: 16px;
    border-radius: 10px;
  }
  
  .main-referral-company-otherdetails {
    margin-left: 10px;
    font-size: 14px;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .main-referral-description {
    padding: 10px;
    font-size: 14px;
    color: dimgray;
    font-weight: 400;
  }
  
  .main-referral-applynow {
    display: flex;
    flex-direction: row;
  }
  
  .main-referral-domain {
    padding: 10px;
    background-color: aquamarine;
    border-radius: 10px;
    width: fit-content;
  }
  
  .referral-filters-search-box {
  }
  
  .main-referral-modal-header {
    border-bottom: none;
  }
  
  .main-referral-modal-header-cross {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
  }
  
  .main-referral-send-email-modal-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .main-referral-send-email-modal-block div {
    margin: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  
  .main-referral-send-email-modal-block p {
    margin-top: 20px;
  }
  
  .main-referral-send-email-modal-block span {
    cursor: pointer;
    color: #007bff;
  }
  
  .main-referral-modal-header-cross i {
    font-size: 20px;
    cursor: pointer;
  }
  
  .main-referral-modal-body {
    padding: 10px;
    margin: 0px;
    margin-top: 0px;
  }
  
  .main-referral-modal-feedback-body p {
    font-size: 20px;
    font-weight: 400px;
    color: #007bff;
    margin-bottom: 0px;
  }
  
  .main-referral-modal-feedback-body textarea {
    margin: 10px 0px;
  }
  
  .main-referral-modal-feedback-body button {
    outline: none;
    border: none;
    background-color: #007bff;
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .main-referral-no-extension {
    height: auto;
    background-color: aliceblue;
    box-shadow: 1px 1px 1.5px 1.5px #efefef, 1px 1px 1.5px 1.5px #efefef;
    width: 100%;
    flex-direction: column;
    /* margin: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    padding: 50px 0px;
  }
  
  .main-referral-no-extension button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    outline: none;
    border: none;
    background-color: darkcyan;
    border-radius: 10px;
    color: white;
  }
  
  .main-referral-already-installed {
    margin-top: 20px;
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .main-referral-find-employees-init-button {
    background-color: #786ad2;
    outline: none;
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  
  .main-referral-find-employees-init-button:hover {
    border: 2px solid darkblue;
  }
  
  .main-referral-find-employees-init-button i {
    color: white;
    font-size: 15px;
    margin-right: 10px;
  }
  
  .main-referral-no-extension img {
    width: 35px;
    margin-right: 10px;
  }
  
  .modal-email-sent-success-div {
    padding: 20px;
    font-weight: 500;
  }
  
  .modal-email-sent-success-div span {
    text-decoration: underline;
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {
    .main-referral-top {
      flex-direction: column;
    }
  
    .main-referral-top-second {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis; 
      white-space: nowrap; 
      display: block;
      max-width: 100%;
    }
  
    .main-referral-top-second div {
      margin: 5px 0px;
      overflow: hidden;
      text-overflow: ellipsis; 
      white-space: nowrap; 
      display: block;
      max-width: 100%;
      
    }
  
    .main-referral-company-otherdetails {
      text-align: center;
    }
  
    .main-referral-top-first {
      padding: 10px 0px 0px 0px;
    }
  
    .main-referral-top-third {
      display: flex;
      justify-content: center;
      align-content: center;
      margin: 20px 0px;
    }
  
    .referral-fitlers-search-box-button-block button {
      width: 100px;
    }
  
    .referral-fitlers-select-input {
      margin: 5px;
    }
  
    .main-referral-container {
      padding: 5px;
      margin: 10px 0px 10px 0px;
    }
  
    .main-referral-no-extension {
      text-align: center;
      padding: 20px;
      font-size: 18px;
    }
  }
  