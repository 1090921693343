/* Custom dropdown toggle button - fully transparent without borders, shadows, or outlines */
.custom-dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: slateblue !important;
  font-weight: 500;
  padding: 8px 16px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  font-size: 16px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

/* Hover effect for dropdown toggle button */
.custom-dropdown-toggle:hover {
  background-color: #f0eefa !important; /* Light background color for hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Subtle box shadow */
}

/* Remove focus outline from dropdown toggle button */
.custom-dropdown-toggle:focus {
  outline: none;
}

/* Dropdown menu styling with rounded corners and no border */
.custom-dropdown-menu {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 0;
}

/* Custom dropdown item styling - transparent background with hover effect */
.custom-dropdown-menu .dropdown-item {
  background-color: transparent !important;
  padding: 10px 16px;
  color: #333 !important;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
  font-size: 14px;
}

/* Hover effect for dropdown items */
.custom-dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa !important; /* Light grey for hover effect */
  color: black !important; /* Blue color to match ChatGPT hover style */
}

/* Selected item styling with checkmark */
.custom-dropdown-menu .dropdown-item.selected-item {
  font-weight: bold;
  background-color: #f0eefa !important; /* Light grey for selected item */
}

/* Custom styling for the informational label inside the dropdown */
.dropdown-info-label {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
  background-color: #f8f9fa; /* Light background for distinction */
}

.dropdown-info-label strong {
  display: block;
  font-weight: bold;
  color: #333;
}

.dropdown-info-label p {
  margin: 4px 0 0;
  color: #666;
  font-size: 12px;
}
