.referral-filters-search-box {
  display: flex;
  flex-direction: row;
  margin: 20px;
}

.main_referral_select_companies {
  width: 200px;
}

.referral-fitlers-seach-box-block {
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 5px;
}

.referral-fitlers-search-box-button-block {
  padding: 5px;
}

.referral-fitlers-search-box-button-block button {
  width: 150px;
  height: 100%;
  border: none;
  background-color: #007bff;
  color: white;
  outline: none;
  border-radius: 5px;
}

.referral-fitlers-search-box-button-block-reset button {
  border-radius: 10px;
  width: auto;
  outline: none;
  color: black;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  background-color: whitesmoke;
  border: 2px solid orange;
}

.referral-fitlers-search-box-button-block i {
  color: white;
  margin-right: 10px;
}

.referral-filters-select {
  width: 100%;
  margin: 20px;
}

.referral-filters-most-popular {
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  background-color: whitesmoke;
  border: 2px solid orange;
}

.referral-fitlers-select-input {
  display: inline-block;
  margin: 10px;
  font-size: 14px;
}

.referral-fitlers-select-input select {
  border-radius: 10px;
  border: 2px solid #007bff;
}

.referral-filters-most-popular_selected {
  border: 2px solid blue;
  background-color: #007bff;
  color: white;
}

.referral-filters-most-popular_selected i {
  color: white;
}
