@import url(https://fonts.googleapis.com/css?family=Lato:700);

.referral-pricing-main-heading-container {
  margin-bottom: 80px;
}

.card {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  flex: 1;
  border-radius: 30px;
  margin: 8px;
  padding: 30px;
  position: relative;
  text-align: center;
  transition: all 0.5s ease-in-out;
}

.card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.referral-pricing-container-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafe;
  padding: 20px;
}

.referral-pricing-container {
  width: 90%;
  height: 100%;
  margin: 0px !important;
  padding: 50px !important;
}

.pricing-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  background-color: #fafafe !important;
  flex: 1;
  margin-top: 40px;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.preferred-card {
  transform: scale(1.1);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: relative;
  background-color: #f0eefa !important;
  margin-top: 40px;
}

.preferred-card:hover {
  transform: scale(1.15);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
}

.features-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
}

.features-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.features-list li svg {
  color: slateblue;
  margin-right: 8px;
}

.banner {
  background-color: slateblue;
  color: #fafafe;
  text-align: center;
  padding: 20px;
  font-weight: bold;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 15px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-radius: 10px;
}

.enhanced-banner {
  font-size: 1.2rem;
  padding: 10px;
  color: #fff;
  background-image: linear-gradient(45deg, #6a5acd, #836fff);
  margin-bottom: 30px;
}

.banner-highlight {
  font-weight: bold;
  color: #ffeb3b;
  font-size: 1.5rem;
}

.select-plan-button {
  background-color: transparent;
  border: 2px solid slateblue;
  color: slateblue;
  padding: 10px 20px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.select-plan-button:hover {
  background-color: hsl(250, 55%, 96%);
  color: slateblue;
  border: 2px solid slateblue;
}

.select-plan-button:focus {
  outline: none;
  box-shadow: none;
  background-color: transparent; /* or any color you want */
  color: slateblue; /* or maintain your button's text color */
}

.select-plan-button:active {
  background-color: transparent; /* or any color you want */
  color: slateblue; /* maintain your desired text color */
  border-color: slateblue; /* maintain your border color if needed */
  outline: none;
  box-shadow: none;
}

.heading {
  color: slateblue;
  font-size: 2.5rem;
  font-weight: bold;
}

.subheading {
  color: #555;
  font-size: 1.25rem;
}

.features-list li::before {
  content: none;
}

.features-list li i {
  color: slateblue;
  margin-right: 20px;
}

.features-list li:not(:last-child) {
  border-bottom: none !important;
}

.pricing-card-body {
  background-color: #fff;
  padding: 2rem;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  .pricing-card {
    transform: scale(1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  .preferred-card {
    transform: scale(1);
  }
  .banner {
    font-size: 0.9rem;
  }
  .heading {
    font-size: 2rem;
  }
  .subheading {
    font-size: 1rem;
  }
  .select-plan-button {
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 1.75rem;
  }
  .subheading {
    font-size: 0.9rem;
  }
  .features-list li {
    margin-bottom: 8px;
  }
  .select-plan-button {
    padding: 6px 12px;
  }
}
