.main-referral-email-title {
  margin: 20px;
}

.main-referral-email-body {
  margin: 20px;
}

.main-referral-emailsend-button {
  margin: 20px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.main-referral-emailsend-button button {
  /* border: none; */
  /* background-color: #007bff;
  color: white;
  padding: 10px 20px;
  outline: none;
  border-radius: 5px;
  margin-left: 20px; */
  font-weight: bold;
  background-color: white;
}

.main-referral-goback {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: right;
  align-items: center;
}

.main-referral-email-error-activate {
  background-color: oldlace;
  padding: 20px;
  color: black;
  border: 2px solid red;
  border-radius: 5px;
}

.main-referral-email-error-createprofile {
  outline: none;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 10px;
}

.main-referral-emamil-error-createprofile-block p {
  font-size: 16px;
  color: blue;
  cursor: pointer;
  margin-bottom: 20px;
}

.main-referral-emamil-error-createprofile-block {
  margin: 20px 0;
}

.main-referral-email-error-button {
  outline: none;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: green;
  color: white;
  font-weight: bold;
}

.main-referral-custom-template {
  background: #f0eefa;
  padding: 20px;
  margin-bottom: 20px;
  /* border: 2px solid green; */
  box-shadow: 1px 1px 1.5px 1.5px #efefef, 1px 1px 1.5px 1.5px #efefef;
}

.main-referral-custom-template .form-control {
  font-size: 14px !important;
}

.main-referral-no-templates {
  color: black;
  cursor: pointer;
  margin-top: 20px;
}

.main-referral-custom-template-save-button {
  outline: none;
  border: none;
  margin-top: 20px;
  padding: 5px 10px;
  background-color: slateblue;
  color: white;
  border-radius: 5px;
}

.main-referral-custom-template-save-button-disabled {
  background-color: grey;
}

.main-referral-select-custom-template p {
  margin-top: 10px;
  margin-bottom: 5px;
  color: gray;
}

.main-referral-no-templates span {
  /* color: #007bff; */
  cursor: pointer;
}

.main-referral-custom-template label {
  font-size: 14px;
  color: black;
  font-weight: 600;
  margin-top: 15px;
}

.main-referral-edit-email-settings-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-top: 10px;
}

.main-referral-edit-email-settings {
  border: none;
  outline: none;
  width: auto;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  font-weight: bold;
  padding: 5px 10px;
  border: 2px solid green;
}

.ql-container {
  height: auto !important;
  min-height: 100%;
  border: 1px solid #ccc;
}

.ql-editor {
  min-height: 100%;
  overflow-y: auto;
}

.ql-toolbar {
  border-bottom: 1px solid #ccc;
}

.template-modal-main-modal-body {
  padding: 10px 1rem 0px 1rem !important;
  margin: 0px;
}

.template-modal-main .text-muted {
  margin: 0px 15px 10px 15px;
  font-size: 14px;
}

.template-modal-main .text-muted span {
  color: slateblue;
  font-weight: 500;
}