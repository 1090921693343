.custom-template-container {
  margin-top: 20px;
  width: 100%;
  background-color: #fafafe;
  padding: 20px 0px 20px 10px;
}

.custom-template-container.custom-template-container-empty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-template {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 20px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.email-header h4 {
  color: slateblue;
}

.custom-template-update {
  outline: none;
  border: none;
  background-color: darkcyan;
  color: white;
  padding: 8px;
  border-radius: 5px;
}

.custom-template-delete {
  outline: none;
  border: none;
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 5px;
}

.email-header {
  background-color: #f1f1f1;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.email-header h5 {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
}

.email-body {
  padding: 15px;
  font-family: "Courier New", Courier, monospace; /* Email-like font */
  font-size: 1rem;
  white-space: pre-wrap; /* Keep formatting of line breaks */
}

.email-body .placeholder {
  color: #007bff; /* Blue color for placeholders */
  font-weight: bold;
}

.empty-template-card {
  margin-top: 50px !important; /* Center the card vertically */
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.empty-template-card .card-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: slateblue;
}

.empty-template-card .card-text {
  font-size: 1.4rem;
  margin-bottom: 20px;
  font-weight: 300;
}

.empty-template-card .btn-primary {
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: slateblue;
  border-radius: 10px;
}

/* .modal-lg {
  max-width: 90%;
} */

.react-quill {
  height: 300px;
  max-height: 500px;
}

.template-modal-main .modal-header {
  display: flex;
}

.template-modal-main .modal-header .modal-title {
  width: 100%;
}

.template-modal-main .ql-editor {
  height: 300px !important;
}
