.progress_bar_lazyApply {
  margin: auto;
  width: 200px;
  max-width: 80%;
  height: 15px;
  box-shadow: 0px 0px 4px #ffffffd1;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
.progress_bar_lazyApply:before {
  content: "";
  width: 200%;
  height: 100%;
  position: absolute;
  animation: progress-anime-lazyapply 4.5s ease-out infinite;
  background: linear-gradient(
    to right,
    slateblue 0%,
    #968bdc 50%,
    #c3bdeb 50%,
    #fafafe 100%
  );
}

@keyframes progress-anime-lazyapply {
  0% {
    left: -100%;
  }
  75% {
    left: -5%;
  }
  85% {
    left: -5%;
  }
  95% {
    left: 0;
  }
}
